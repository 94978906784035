<script setup>
import { reactive, ref, defineProps } from '@vue/reactivity';
import ChartService from '../../services/ChartService';
/* Props */
const props = defineProps( {
    referenceDate: String,
    totalProducts: Number
});
/* Data */
const {productsByCount} = ChartService();
//const route = useRoute();
const pageStatus = ref('loading');
const v2l1 = ref(0);
const v3l1 = ref(0);
const v2l2 = ref(0);
const v3l2 = ref(0);
const v2l3 = ref(0);
const v3l3 = ref(0);
const conformant = ref(0);
const expired = ref(0);
const notEvaluated = ref(0);
const notPublished = ref(0);
const series= ref([]);
const chartOptions = reactive(
    {
    chart: {
        type: 'donut',
        width: '100%'
    },
    colors:['#d5dfa7', '#acc051', '#a4d6a6', '#4caf50', '#cd92d7','#9c27b0', '#71717A', '#03a9f4', '#f44336'],
    labels:[],
    legend: {
      show: false,
    },
    dataLabels: {
        formatter: function (val, opts) {
            return opts.w.config.series[opts.seriesIndex]
        },
    },
    responsive: [{
        breakpoint: 480,
        options: {
        chart: {
            width: 100
        },
        legend: {
            position: 'bottom'
        }
        }
    }]
    },
);
/* Methods */
const init = async () => 
{
    pageStatus.value = 'loading';
    const res = await productsByCount(props.referenceDate);
    if(res?.data)
    {
        chartOptions.labels.push('ZDHC MRSL v2.0 - Levels 1');
        chartOptions.labels.push('ZDHC MRSL v3.1 - Levels 1');
        chartOptions.labels.push('ZDHC MRSL v2.0 - Levels 2');
        chartOptions.labels.push('ZDHC MRSL v3.1 - Levels 2');
        chartOptions.labels.push('ZDHC MRSL v2.0 - Levels 3');
        chartOptions.labels.push('ZDHC MRSL v3.1 - Levels 3');
        chartOptions.labels.push('Expired');
        chartOptions.labels.push('Not published');
        chartOptions.labels.push('Not evaluated');

        series.value.push(res.data.mrsl_v_2_lv_1);
        v2l1.value = res.data.mrsl_v_2_lv_1;
        series.value.push(res.data.mrsl_v_3_lv_1);
        v3l1.value = res.data.mrsl_v_3_lv_1;
        series.value.push(res.data.mrsl_v_2_lv_2);
        v2l2.value = res.data.mrsl_v_2_lv_2;
        series.value.push(res.data.mrsl_v_3_lv_2);
        v3l2.value = res.data.mrsl_v_3_lv_2;
        series.value.push(res.data.mrsl_v_2_lv_3);
        v2l3.value = res.data.mrsl_v_2_lv_3;
        series.value.push(res.data.mrsl_v_3_lv_3);
        v3l3.value = res.data.mrsl_v_3_lv_3;
        series.value.push(res.data.expired);
        expired.value = res.data.expired;
        series.value.push(res.data.not_published);
        notPublished.value = res.data.not_published;
        /* series.value.push(res.data.not_evaluated); */
        notEvaluated.value = res.data.not_evaluated;
        conformant.value = res.data.conformant;
    }
    if(res) pageStatus.value ="loaded";
    
}
/* Hooks */
init();
</script>
<template>
    <div class="bg-white p-2 w-full grid grid-cols-1 xl:grid-cols-2 gap-5">
        <div v-if="pageStatus !== 'loading'" class="h-full w-full relative">
            <apexchart
                type="donut"
                :options="chartOptions"
                :series="series"
            ></apexchart>
            <div class="absolute inset-0 flex flex-col space-y-2 items-center justify-center">
                <h2 class="text-3xl font-semibold">
                    {{ props.totalProducts }}
                </h2>
                <h2 class="text-xl font-semibold">
                    Total products
                </h2>
            </div>
        </div>
        <div v-else class="h-full flex items-center justify-center bg-gray-200 w-full">
            <svg class="animate-spin w-8 text-blue-500 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>
        </div>
        <div class="w-full flex flex-col space-y-2">
            <div class="grid grid-cols-3 w-full gap-2">
                <div class="p-2"></div>
                <div class="p-2 flex items-center justify-center">
                    <span class="text-sm">ZDHC MRSL v2.0</span>
                </div>
                <div class="p-2 flex items-center justify-center">
                    <span class="text-sm">ZDHC MRSL v3.1</span>
                </div>
            </div>
            <!-- Level 1 -->
            <div class="grid grid-cols-3 w-full gap-2">
                <div class="flex flex-col p-1">
                    <h2 class="text-light-green-500">
                        Level 1
                    </h2>
                    <span class="text-xs">
                        Passed a third-party review of documentation where the data meet the QA and QC requirements in the MRSL Conformance Guidance to be accepted as evidence of conformance.
                    </span>
                </div>
                <div class="p-1 flex items-center justify-center text-sm" style="background-color:#d5dfa7">
                    <span class="font-medium">{{ v2l1 }} products</span>
                </div>
                <div class="p-1 flex items-center justify-center text-sm" style="background-color:#acc051">
                    <span class="font-medium">{{ v3l1 }} products</span>
                </div>
            </div>
            <!-- Level 2 -->
            <div class="grid grid-cols-3 w-full gap-2">
                <div class="flex flex-col p-1">
                    <h2 class="text-green-500">
                        Level 2
                    </h2>
                    <span class="text-xs">
                        All requirements for Level 1 passed and passed a review of the product stewardship practices of the chemical supplier by the third-party certifier.
                    </span>
                </div>
                <div class="p-1 flex items-center justify-center text-sm" style="background-color:#a4d6a6">
                    <span class="font-medium">{{ v2l2 }} products</span>
                </div>
                <div class="p-1 flex items-center justify-center text-sm" style="background-color:#4caf50">
                    <span class="font-medium">{{ v3l2 }} products</span>
                </div>
            </div>
            <!-- Level 3 -->
            <div class="grid grid-cols-3 w-full gap-2">
                <div class="flex flex-col p-1">
                    <h2 class="text-purple-500">
                        Level 3
                    </h2>
                    <span class="text-xs">
                        All requirements for Level 2 passed and passed a site visit to the chemical Formulator to evaluate their product stewardship first-hand.
                    </span>
                </div>
                <div class="p-1 flex items-center justify-center text-sm" style="background-color:#cd92d7">
                    <span class="font-medium">{{ v2l3 }} products</span>
                </div>
                <div class="p-1 flex items-center justify-center text-sm" style="background-color:#9c27b0">
                    <span class="font-medium">{{ v3l3 }} products</span> 
                </div>
            </div>
            <!-- Conformant -->
            <div class="grid grid-cols-3 w-full gap-2">
                <div class="flex flex-col p-1">
                    <h2 class="text-teal-500">
                        Conformant
                    </h2>
                    <span class="text-xs">
                        Products are ZDHC MRSL Conformant (ZDHC MRSL v2.0 and/or ZDHC MRSL v3.1) and have been uploaded and published on the ZDHC Gateway by the Formulator.
                    </span>
                </div>
                <div class="col-span-2 p-1 flex items-center justify-center text-sm" style="background-color:#009688">
                    <span class="font-medium">{{ conformant }} products</span>
                </div>
            </div>
            <!-- Expired -->
            <div class="grid grid-cols-3 w-full gap-2">
                <div class="flex flex-col p-1">
                    <h2 class="text-gray-500">
                        Expired
                    </h2>
                    <span class="text-xs">
                        Products that have expired from ZDHC MRS Lv2.0 or ZDHC MRS Lv3.0 Conformance.
                    </span>
                </div>
                <div class="col-span-2 p-1 flex items-center justify-center text-sm" style="background-color:#71717A">
                    <span class="font-medium">{{ expired }} products</span>
                </div>
            </div>
            <!-- Not Published -->
            <div class="grid grid-cols-3 w-full gap-2">
                <div class="flex flex-col p-1">
                    <h2 class="text-light-blue-500">
                        Not Published
                    </h2>
                    <span class="text-xs">
                        Products that are not published on the the ZDHC Gateway with a valid ZDHC MRS Lv2.0 or ZDHC MRS Lv3.0 Conformance Certification.
                    </span>
                </div>
                <div class="col-span-2 p-1 flex items-center justify-center text-sm" style="background-color:#03a9f4">
                    <span class="font-medium">{{ notPublished }} products</span>
                </div>
            </div>
            <!-- Not Evaluated -->
            <div class="grid grid-cols-3 w-full gap-2">
                <div class="flex flex-col p-1">
                    <h2 class="text-red-500">
                        Not Evaluated
                    </h2>
                    <span class="text-xs">
                        Products that are either not published in the ZDHC Gateway by the Formulators as ZDHC MRSL Level 1, 2 or 3, or published expired product status only.
                    </span>
                </div>
                <div class="col-span-2 p-1 flex items-center justify-center text-sm" style="background-color:#f44336">
                    <span class="font-medium">{{ notEvaluated }} products</span>
                </div>
            </div>
            
        </div>
    </div>
</template>