<script setup>
import moment from 'moment';
import { useStore }     from 'vuex';
import { useI18n }      from 'vue-i18n';
import { defineEmits, defineProps, ref, defineAsyncComponent} from 'vue';
/* Components */
const ReportPreviewHeaderBarChart = defineAsyncComponent( () => import('../charts/ReportPreviewHeaderBarChart'));
const ChemicalInventoryPerformancePieChart = defineAsyncComponent( () => import('../charts/ChemicalInventoryPerformancePieChart'));
const ProductsByCountChart = defineAsyncComponent( () => import('../charts/ProductsByCountChart.vue'));
const ProductsByQuantityChart = defineAsyncComponent( () => import('../charts/ProductsByQuantityChart.vue'));
const LegendComponent = defineAsyncComponent( () => import('../charts/LegendComponent.vue'));
/* Emits */
const emit = defineEmits(['closeModal']);
/* Props */
const props = defineProps({
    referenceMonth: {
        required: true
    },
    referenceYear: {
        required:true
    },
    products: Number,
    selectedDate: String
})
/* Data */
const store = useStore();
const referenceDate = ref('');
const {t, locale} = useI18n();
/* Hooks */
locale.value = store.state.locale;
referenceDate.value = t(`months.${moment(props.selectedDate).locale('en').format('MMM').toLocaleLowerCase()}`, locale);
</script>
<template>
    <div class="fixed inset-0 bg-white p-10 w-full h-full z-31 flex flex-col space-y-10 overflow-y-auto">
        <div class="w-full flex items-center justify-end">
            <button 
                title="Chiudi"
                @click.prevent="emit('closeModal')"
                class="ml-auto bg-transparent min-w-min flex items-center justify-center transform hover:rotate-90 duration-300">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 64 64" class="w-4 fill-current text-gray-800 dark:text-gray-400">
                        <path d="M34.5,32L62.2,4.2c0.7-0.7,0.7-1.8,0-2.5c-0.7-0.7-1.8-0.7-2.5,0L32,29.5L4.2,1.8c-0.7-0.7-1.8-0.7-2.5,0
                            c-0.7,0.7-0.7,1.8,0,2.5L29.5,32L1.8,59.8c-0.7,0.7-0.7,1.8,0,2.5c0.3,0.3,0.8,0.5,1.2,0.5s0.9-0.2,1.2-0.5L32,34.5l27.7,27.8
                            c0.3,0.3,0.8,0.5,1.2,0.5c0.4,0,0.9-0.2,1.2-0.5c0.7-0.7,0.7-1.8,0-2.5L34.5,32z"/>
                    </svg>
            </button>
        </div>
        <div class="flex flex-col space-y-5">
            <h1 class="text-2xl dark:text-white">
                {{ t('reportPreview.title', locale.value) }}
            </h1>
            <h2 class="text-xl text-gray-500">
                Performance InCheck Report - {{ referenceDate }} {{ props.referenceYear }}
            </h2>
            <div class="flex items-baseline justify-start space-x-2">
                <span>
                    {{  t('sidebar.products', locale) }}:
                </span>
                <span class="text-3xl font-semibold">
                    {{ props.products }}
                </span>
            </div>
            <ReportPreviewHeaderBarChart 
                :referenceDate="props.selectedDate"
                :totalProducts="props.products"
            />
            <div class="flex items-center justify-start space-x-2">
                <span class="text-xl font-semibold">
                    Chemical Inventory Performance
                </span>
            </div>
            <ChemicalInventoryPerformancePieChart 
                :referenceDate="props.selectedDate"
                :totalProducts="props.products"
            />
            <div class="flex items-center justify-start space-x-2">
                <span class="text-xl font-semibold">
                    Product overview by count and weight
                </span>
            </div>
            <div class="flex flex-col space-y-3">
                <div class="grid grid-cols-1 lg:grid-cols-2 gap-5 w-full">
                    <ProductsByCountChart 
                        :referenceDate="props.selectedDate"
                        :totalProducts="props.products"
                    />
                    <ProductsByQuantityChart 
                        :referenceDate="props.selectedDate"
                        :totalProducts="props.products"
                    />
                </div>
                <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 w-full">
                    <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
                        <LegendComponent 
                            color="#d5dfa7"
                            label="ZDHC MRSL v2.0 - Levels 1"
                        />
                        <LegendComponent 
                            color="#acc051"
                            label="ZDHC MRSL v3.1 - Levels 1"
                        />
                        <LegendComponent 
                            color="#a4d6a6"
                            label="ZDHC MRSL v2.0 - Levels 2"
                        />
                        <LegendComponent 
                            color="#4caf50"
                            label="ZDHC MRSL v3.1 - Levels 2"
                        />
                        <LegendComponent 
                            color="#cd92d7"
                            label="ZDHC MRSL v2.0 - Levels 3"
                        /> 
                        <LegendComponent 
                            color="#9c27b0"
                            label="ZDHC MRSL v3.1 - Levels 3"
                        />
                    </div>
                    <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
                        <LegendComponent 
                        color="#71717A"
                        label="Expired"
                        />
                        <LegendComponent 
                            color="#009688"
                            label="Conformant"
                        />
                        <LegendComponent 
                            color="#03a9f4"
                            label="Not published"
                        />
                        <LegendComponent 
                            color="#f44336"
                            label="Not evaluated"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>