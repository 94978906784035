<script setup>
import { reactive, ref, /* onMounted, */ defineProps } from '@vue/reactivity';
//import { useRoute } from 'vue-router';
import ChartService from '../../services/ChartService';
import { useStore } from 'vuex';
/* Props */
const props = defineProps( {
    referenceDate: String,
    totalProducts: Number
});
/* Data */
const store = useStore()
const {headerCount} = ChartService();
//const route = useRoute();
const weeklyArticlesAnalytics = ref(null);
const pageStatus = ref('loading');
const series = ref([
    {
        name: "Products in Gateway",
        data: [],
    },
    {
        name: "ZDHC MRSL v2.0 - Levels 1, 2, 3",
        data: [],
    },
    {
        name: "ZDHC MRSL v3.1 - Levels 1, 2, 3",
        data: [],
    }
]);
const chartOptions = reactive({
    chart: {
        type: 'bar',
        toolbar:
        {
            show:false
        },
        foreColor: store.state.theme === 'dark' ? '#e4e4e7' : '#18181b'
    },
    legend: {
      show: false,
    },
    dataLabels: 
    {   
        enabled: false  
    },
    plotOptions: {
        bar: {
            barHeight: '100%',
            horizontal: true,
        },
    },
    stroke: {
    colors: ['transparent'],
        width: 4,    
    },
    grid:
    {
        show:true,
        borderColor: '#a1a1aa',
    },
    tooltip: 
    {
        enabled: true,
        x: {
            show: true,
        },
        y:
        {
            show:true,
            formatter: function(val) {
                return val.toFixed(0);
            }
        },
        custom: function({series, seriesIndex, dataPointIndex}) 
        {
            if(seriesIndex === 0)
            {
                let productsInGateway = series[0][dataPointIndex];
                return `<div class="px-3 py-2 flex items-center justify-center space-x-1">
                        <span >Products in gateway:</span>
                        <span class="font-semibold">${productsInGateway} / ${props.totalProducts}</span>
                    </div>`;
            }
            else if(seriesIndex === 1)
            {
                let v2CertificationPercentage = (series[1][dataPointIndex] * 100) / props.totalProducts;
                return `<div class="px-3 py-2 flex items-center justify-center space-x-1">
                        <span >ZDHC MRSL v2.0 - Levels 1, 2, 3:</span>
                        <span class="font-semibold">${v2CertificationPercentage.toFixed(2)}%</span>
                    </div>`;
            }
            else 
            {
                let v3CertificationPercentage = (series[2][dataPointIndex] * 100) / props.totalProducts;
                return `<div class="px-3 py-2 flex items-center justify-center space-x-1">
                        <span >ZDHC MRSL v3.1 - Levels 1, 2, 3:</span>
                        <span class="font-semibold">${v3CertificationPercentage.toFixed(2)}%</span>
                    </div>`;
            }
        }
    },
    xaxis: 
    {
        categories: [],
        labels:
        {
            show:true,
            style: {
                colors: [],
                fontSize: '10px',
            },
        }
    },
    yaxis: {
        forceNiceScale: true,
        labels:
        {
            show:true
        },
    },
});
/* Methods */
const init = async () => 
{
    pageStatus.value = 'loading';
    const res = await headerCount(props.referenceDate);
    if(res?.data)
    {
        chartOptions.xaxis.categories.push('Products in Gateway');
        chartOptions.xaxis.categories.push('ZDHC MRSL v2.0 - Levels 1, 2, 3');
        chartOptions.xaxis.categories.push('ZDHC MRSL v3.1 - Levels 1, 2, 3');
        series.value[0].data.push(res.data.in_gateway_products);
        series.value[1].data.push(res.data.mrsl_v_2_certification);
        series.value[2].data.push(res.data.mrsl_v_3_certification);
    }
    if(res) pageStatus.value ="loaded";
    
}
/* Hooks */
init();
/* onMounted( () => 
{
    chartOptions.xaxis.categories.push('Products in Gateway');
    series.value[0].data.push(e.new);
}) */
</script>
<template>
    <div class="bg-white dark:bg-zinc-800 p-2 rounded">
        <div v-if="pageStatus !== 'loading'" class="h-full min-h-250px max-h-250px">
            <apexchart
                ref="weeklyArticlesAnalytics"
                height="100%"
                :options="chartOptions"
                :series="series"
            ></apexchart>
        </div>
        <div v-else class="h-[250px] min-h-250px max-h-250px flex items-center justify-center bg-gray-200">
            <svg class="animate-spin w-8 text-blue-500 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>
        </div>
    </div>
</template>