<script setup>
import { ref, defineEmits, defineProps, onMounted, onUnmounted, computed, defineAsyncComponent} from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore} from 'vuex';
/* Components */
const UpdatePidSection = defineAsyncComponent( () => import('./ProductStatusModal/UpdatePidSection.vue'));
const NoMatchSection = defineAsyncComponent( () => import('./ProductStatusModal/NoMatchSection.vue'));
const MatchSection = defineAsyncComponent( () => import('./ProductStatusModal/MatchSection.vue'));
/* Emits */
const emits = defineEmits(['closeModal', 'PidUpdated', 'newNoMatch']);
/* Props */
const props = defineProps({
    product:{required:true},
    productStatus:{required:true},
});
/* Data */
const sections = ['match', 'noMatch', 'updatePid'];
const activeSection = ref('updatePid');
const { t } = useI18n();
const store = useStore();
/* Computed */
const modalTitle = computed(() => 
{
    switch(activeSection.value)
    {
        case 'match':
            return 'Match';
        case 'noMatch':
            return t('noMatchModal.title', store.state.locale);
        case 'updatePid':
            return t('updatePidModal.title', store.state.locale);
        default:
            return props.product.name
    }
});
/* Methods */
const init = () =>
{
    switch(props.productStatus)
    {
        case 'success':
            return activeSection.value = 'match';
        case 'manualNoMatch':
            return activeSection.value = 'noMatch';
        case 'no_pid': default:
            return activeSection.value = 'updatePid';
    }
}
const pidUpdated = (newPid) => emits('PidUpdated', newPid);
const newNoMatch = (noMatchGuid) => emits('newNoMatch', noMatchGuid);
/* Hooks */
// change activeSection based on props.productStatus
init();
onMounted( () => document.body.classList.add('overflow-hidden'));
onUnmounted( () => document.body.classList.remove('overflow-hidden'));
</script>
<template>
    <div
        @click.prevent="emits('closeModal')" 
        class="fixed inset-0 p-6 bg-gray-1000 bg-opacity-90 z-30 overscroll-y-contain overflow-y-auto">
        <div class="flex flex-col w-full h-full items-center justify-center">
            <div
                @click.stop 
                class="bg-white dark:bg-gray-900 border dark:border-gray-700 rounded p-5 flex flex-col space-y-5 w-full md:w-2/3 lg:w-1/2 2xl:w-1/3 dark:text-gray-400">
                <div class="flex items-center justify-between">
                    <h2 class="text-xl font-bold flex items-center space-x-1">
                        {{ modalTitle }}
                    </h2>
                    <button 
                        :title="t('actions.close', store.state.locale)"
                        @click.prevent="emits('closeModal')"
                        class="bg-transparent min-w-min flex items-center justify-center transform hover:rotate-90 duration-300">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 64 64" class="w-4 fill-current text-gray-800 dark:text-gray-400">
                                <path d="M34.5,32L62.2,4.2c0.7-0.7,0.7-1.8,0-2.5c-0.7-0.7-1.8-0.7-2.5,0L32,29.5L4.2,1.8c-0.7-0.7-1.8-0.7-2.5,0
                                    c-0.7,0.7-0.7,1.8,0,2.5L29.5,32L1.8,59.8c-0.7,0.7-0.7,1.8,0,2.5c0.3,0.3,0.8,0.5,1.2,0.5s0.9-0.2,1.2-0.5L32,34.5l27.7,27.8
                                    c0.3,0.3,0.8,0.5,1.2,0.5c0.4,0,0.9-0.2,1.2-0.5c0.7-0.7,0.7-1.8,0-2.5L34.5,32z"/>
                            </svg>
                    </button>
                </div>
                <template v-if="activeSection == 'match'">
                    <MatchSection 
                        :product="props.product"
                    />
                </template>
                <template v-else-if="activeSection == 'noMatch'">
                    <NoMatchSection 
                        :product="props.product"  
                        @updatePid="activeSection = 'updatePid'"                  
                    />
                </template>
                <template v-else>
                    <UpdatePidSection 
                        :product="props.product"
                        :productStatus="props.productStatus"
                        @PidUpdated="pidUpdated"
                        @newNoMatch="newNoMatch"
                    />
                </template>
            </div>
        </div>
    </div>
</template>
